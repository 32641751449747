import React, { useState } from "react";
import Select from "react-select";
import Input from "../components/Input";
import Handlebars from "handlebars";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactJson from "react-json-view";
import GenericWorkflow from "../components/Form/GenericWorkflow";

const RuleBuilder = ({ jsonData, templateTypesOptions }) => {
    const [selectedTemplateTypeValue, setSelectedTemplateType] = useState(null);

    const [selectedTemplateOptions, setSelectedTemplateOptions] = useState([]);

    const [selectedTemplateName, setSelectedTemplateName] = useState(null);

    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [renderInputs, setRenderInputs] = useState(false);

    const [inputValues, setInputValues] = useState({});
    const [selectValues, setSelectValues] = useState({});
    const [value, setValue] = useState(null);

    const [template, setTemplate] = useState(null);

    const [outputJSON, setOutputJSON] = useState(null);
    const [renderJSON, setRenderJSON] = useState(false);
    const [copied, setCopied] = useState(false);
    const [copyIndex, setCopyIndex] = useState(null);

    const [workFlowDynamicValues, setWorkFlowDynamicValues] = useState(null);

    React.useEffect(() => {
        if (!selectedTemplateTypeValue) {
            return;
        }

        setSelectedTemplate(null);
        setSelectedTemplateName(null);
        setRenderInputs(false);
        setInputValues({});
        setSelectValues({});
        setOutputJSON(null);
        setRenderJSON(false);
        setWorkFlowDynamicValues(null);
        setCopied(false);

        setSelectedTemplateOptions(getSelectedTemplatesOptions(selectedTemplateTypeValue.value));
    }, [selectedTemplateTypeValue]);

    React.useEffect(() => {
        if (selectedTemplateName) {
            setRenderInputs(true);
        } else {
            return;
        }
    }, [selectedTemplateName]);

    const handleInputChange = e => {
        setInputValues({ ...inputValues, [e.target.id]: e.target.value });
    };

    const onSelectChange = (e, input) => {
        setSelectValues({ ...selectValues, [input.value]: e });
        setValue({ ...value, [input.value]: e.value });
    };

    const displayInputs = () => {
        if (selectedTemplate === "EXCEPTION") {
            return (
                <div style={{ border: "1px dotted gray", padding: "1rem" }} className="inputs">
                    <GenericWorkflow type={selectedTemplateName.value} onSubmit={onSubmit} />
                </div>
            );
        }
        const inputs =
            selectedTemplate &&
            selectedTemplate.inputs.map((input, index) => {
                switch (input.type) {
                    case "text":
                        return (
                            <div key={index} className={`input-${index + 1}`}>
                                <Input
                                    key={index}
                                    type="text"
                                    name={input.value}
                                    label={input.label}
                                    placeholder={input.example}
                                    onChange={handleInputChange}
                                    value={inputValues[input.value] || ""}
                                />
                                <small className="text-muted">{input.description}</small>
                                <br />
                            </div>
                        );
                    case "select":
                        return (
                            <div key={index} className={`input-${index + 1}`}>
                                <h6>{input.label}</h6>
                                <Select className="mb-2" value={selectValues[input.value]} onChange={e => onSelectChange(e, input)} options={input.options} />
                                <small className="text-muted">{input.description}</small>
                                <br />
                            </div>
                        );
                    default:
                        return null;
                }
            });

        return (
            <div style={{ border: "1px dotted gray", padding: "1rem" }} className="inputs">
                <small className="text-muted"> {selectedTemplate.description.toUpperCase()}</small>
                <br />
                {inputs}
            </div>
        );
    };

    const getSelectedTemplatesOptions = value => {
        let options;
        switch (value) {
            case "rules":
                options = jsonData["RulesJson"].map(rule => {
                    return {
                        label: rule.label,
                        value: rule.value,
                        type: "rules",
                    };
                });
                return options;
            case "workflows":
                options = jsonData["WorkflowsJson"].map(workflow => {
                    return {
                        label: workflow.label,
                        value: workflow.value,
                        type: "workflows",
                    };
                });
                return options;
            case "widgets":
                options = jsonData["WidgetsJson"].map(widget => {
                    return {
                        label: widget.label,
                        value: widget.value,
                        type: "widgets",
                    };
                });
                return options;
            case "configurations":
                options = jsonData["ConfigsJson"].map(config => {
                    return {
                        label: config.label,
                        value: config.value,
                        type: "configurations",
                    };
                });
                return options;
            case "architectures":
                options = jsonData["ArchitecturesJson"].map(architecture => {
                    return {
                        label: architecture.label,
                        value: architecture.value,
                        type: "architectures",
                    };
                });
                return options;
            default:
                return;
        }
    };

    const handleTemplateTypeOptionsChange = data => {
        setSelectedTemplateType(data);
    };

    const handleTemplateNameOptionsChange = data => {
        setInputValues({});
        setSelectValues({});
        setValue(null);
        setWorkFlowDynamicValues(null);
        setSelectedTemplateName(data);
        setOutputJSON(null);
        setRenderJSON(false);
        setCopied(false);
        let inputs;

        if (data.type === "configurations") {
            inputs = jsonData["ConfigsJson"].find(config => config.value === data.value);
            setTemplate([{ ...jsonData.ConfigurationsTemplate[data.value] }]);
        } else if (data.type === "workflows") {
            let tempInput = jsonData["WorkflowsJson"].find(workflow => workflow.value === data.value);
            if (tempInput.source === "exception") {
                inputs = "EXCEPTION";
            } else {
                inputs = jsonData["WorkflowsJson"].find(workflow => workflow.value === data.value);
            }
            setTemplate([[...jsonData.WorkflowsTemplate[data.value]], { ...jsonData.WorkflowsTriggerTemplate[data.value] }]);
        } else if (data.type === "rules") {
            inputs = jsonData["RulesJson"].find(rule => rule.value === data.value);
            setTemplate([{ ...jsonData.RulesTemplate[data.value] }]);
        } else if (data.type === "widgets") {
            inputs = jsonData["WidgetsJson"].find(widget => widget.value === data.value);
            setTemplate([{ ...jsonData.WidgetsTemplate[data.value] }]);
        } else if (data.type === "architectures") {
            inputs = jsonData["ArchitecturesJson"].find(architecture => architecture.value === data.value);
            setTemplate([{ ...jsonData.ArchitecturesTemplate[data.value] }]);
        }
        setSelectedTemplate(inputs);
    };

    const onSubmit = formValues => {
        setWorkFlowDynamicValues(formValues);
    };

    const handleOnSubmit = e => {
        e.preventDefault();

        let returnObject = [];
        const _template = template.map(tem => {
            return Handlebars.compile(JSON.stringify(tem));
        });

        if (workFlowDynamicValues) {
            _template.forEach(temp => {
                returnObject.push(temp(workFlowDynamicValues));
            });
            if (workFlowDynamicValues.emailFromType === "current-user") {
                let temp = JSON.parse(returnObject[1]);

                let inputs = temp.inputs.map(input => {
                    if (input.name === "from") {
                        return {
                            name: "from",
                            type: "hard-coded",
                            value: { type: "current-user" },
                        };
                    }
                    return input;
                });
                temp = { ...temp, inputs };
                returnObject[1] = JSON.stringify(temp);
            }
            if (!workFlowDynamicValues.entry) {
                returnObject[0] = JSON.stringify([JSON.parse(returnObject[0])[0]]);
                setOutputJSON(returnObject);
                setRenderJSON(true);
            } else {
                setOutputJSON(returnObject);
                setRenderJSON(true);
            }
            return;
        }

        let dynamicValues = { ...inputValues, ...value };
        _template.forEach(temp => {
            returnObject.push(temp(dynamicValues));
        });

        setOutputJSON(returnObject);
        setRenderJSON(true);
    };

    const renderJson = () => {
        return (
            <div style={{ padding: "1rem", margin: "0.5rem" }} className="container">
                {outputJSON.map((output, index) => {
                    return (
                        <div key={index}>
                            {outputJSON.length > 1 && (
                                <div style={{ textAlign: "center" }}>
                                    <span style={{ backgroundColor: "lightgray", padding: "0.5rem", borderRadius: "4px" }}>
                                        {index === 0 ? "Flow Config" : "Trigger Config"}
                                    </span>
                                </div>
                            )}
                            <br />
                            <ReactJson src={JSON.parse(output)} displayObjectSize={false} displayDataTypes={false} />
                            <br />
                            {renderCopy(output, index)}
                            <br />
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderCopy = (output, i) => {
        return (
            <div className="d-flex justify-content-start">
                <CopyToClipboard
                    text={output}
                    onCopy={() => {
                        setCopied(true);
                        setCopyIndex(i);
                    }}
                >
                    <button className="btn btn-primary my-3 mr-3">{copied && i === copyIndex ? "Copied!" : "Copy"}</button>
                </CopyToClipboard>
            </div>
        );
    };

    return (
        <div className="_main">
            <form onSubmit={handleOnSubmit}>
                <h5>Template Type</h5>
                <Select className="mb-2" value={selectedTemplateTypeValue} onChange={handleTemplateTypeOptionsChange} options={templateTypesOptions} />
                <br />
                <h5>Template Name</h5>
                <Select className="mb-2" value={selectedTemplateName} onChange={handleTemplateNameOptionsChange} options={selectedTemplateOptions} />
                <br />
                {renderInputs && displayInputs()}
                <button disabled={!selectedTemplateTypeValue || !selectedTemplateName} className="btn btn-info mt-3">
                    Submit
                </button>

                {renderJSON && renderJson()}
            </form>
        </div>
    );
};

export default RuleBuilder;
