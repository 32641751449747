import React from "react";
import Input from "../Input";
import Select from "react-select";

const GenericWorkflow = ({ type, onSubmit }) => {
    const [formValues, setFormValues] = React.useState({});

    const [selectFromValue, setSelectFromValue] = React.useState(null);
    const [selectToValue, setSelectToValue] = React.useState(null);

    const [ticked, setTicked] = React.useState(true);

    React.useEffect(() => {
        const temp = { ...formValues };
        if (formValues.emailFromType === "current-user") {
            delete temp.emailFromAddress;
            delete temp.emailFromName;
            setFormValues(temp);
        }
    }, [selectFromValue]);

    React.useEffect(() => {
        setFormValues({ ...formValues, entry: ticked });
    }, [ticked]);

    React.useEffect(() => {
        onSubmit(formValues);
    }, [formValues]);

    React.useEffect(() => {
        setFormValues({ entry: true });
        setTicked(true);
        setSelectFromValue(null);
        setSelectToValue(null);
    }, [type]);

    const renderForm = () => {
        if (type === "sendEmail") {
            return (
                <div className="_inputs">
                    <Input
                        type="text"
                        name="emailTemplateId"
                        label="Email Template Id"
                        placeholder="Template ID eg:- 1234"
                        onChange={e => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                        value={formValues["emailTemplateId"] || ""}
                    />
                    <h6>Email From Type</h6>
                    <Select
                        className="mb-3"
                        value={selectFromValue || ""}
                        name="emailFromType"
                        onChange={e => {
                            setFormValues({ ...formValues, emailFromType: e.value });
                            setSelectFromValue(e);
                        }}
                        options={[
                            { label: "Hard Coded", value: "hard-coded" },
                            { label: "Current User", value: "current-user" },
                            { label: "Company Settings", value: "company-settings" },
                        ]}
                    />
                    {formValues["emailFromType"] && formValues["emailFromType"] !== "current-user" && (
                        <>
                            <small className="text-muted">
                                {`>`} If type is hard-coded, use a real name. ie. ABC Company. Else source the internal id of the field (i.e name)
                            </small>
                            <br />
                            <Input
                                type="text"
                                name="emailFromName"
                                label="Type a name with which you want to send an email from?"
                                placeholder="ABC Company / name"
                                onChange={e => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                                value={formValues["emailFromName"] || ""}
                            />

                            <small className="text-muted">
                                {`>`} If type is hard-coded, use a real email address. ie. abc@test.com. Else use the internal id of the field (i.e email)
                            </small>
                            <br />
                            <Input
                                type="text"
                                name="emailFromAddress"
                                label="Type an email address with which you want to send an email from?"
                                placeholder="notifications@kalysys.com.au/email"
                                onChange={e => setFormValues({ ...formValues, [e.target.id]: e.target.value })}
                                value={formValues["emailFromAddress"] || ""}
                            />
                        </>
                    )}

                    <h6>Email To Type</h6>
                    <Select
                        className="mb-3"
                        value={selectToValue || ""}
                        name="emailToType"
                        onChange={e => {
                            setFormValues({ ...formValues, emailToType: e.value });
                            setSelectToValue(e);
                        }}
                        options={[
                            { label: "Hard Coded", value: "hard-coded" },
                            { label: "Dynamic", value: "dynamic" },
                        ]}
                    />
                    <small className="text-muted">
                        {`>`} If type is hard-coded, use a real email address. ie. abc@test.com. Else use the internal id of the field
                    </small>
                    <br />
                    <Input
                        type="text"
                        name="emailToAddress"
                        label="Type an address/email internal-id to which you want to send an email?"
                        placeholder="eg.  / abc@test.com / email / assigned-to.email"
                        onChange={e => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                        value={formValues["emailToAddress"] || ""}
                    />
                </div>
            );
        } else if (type === "sendSms") {
            return (
                <div className="_inputs">
                    <Input
                        type="text"
                        name="smsTemplateId"
                        label="SMS Template Id"
                        placeholder="Template ID eg:- 1234"
                        onChange={e => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                        value={formValues["smsTemplateId"] || ""}
                    />
                    <h6>SMS To Type</h6>
                    <Select
                        className="mb-3"
                        value={selectToValue || ""}
                        name="smsToType"
                        onChange={e => {
                            setFormValues({ ...formValues, smsToType: e.value });
                            setSelectToValue(e);
                        }}
                        options={[
                            { label: "Hard Coded", value: "hard-coded" },
                            { label: "Dynamic", value: "dynamic" },
                        ]}
                    />
                    <small className="text-muted">
                        {`>`} If type is hard-coded, use a real phone number. ie. +61043012567. Else use the internal id of the field
                    </small>
                    <br />
                    <Input
                        type="text"
                        name="smsToPhone"
                        label="Use a phone number/phone field internal-id.."
                        placeholder="eg: +61043012567 / client.phone "
                        onChange={e => setFormValues({ ...formValues, [e.target.name]: e.target.value })}
                        value={formValues["smsToPhone"] || ""}
                    />
                </div>
            );
        }
    };

    return (
        <div className="form-container">
            <div className="entry-div">
                <input checked={ticked} onChange={() => setTicked(!ticked)} type="checkbox" />

                <label style={{ marginLeft: "0.7rem", color: "teal" }} className="label">
                    Enable save/create in respective record and relationship records
                </label>
            </div>
            <br />

            {renderForm()}
        </div>
    );
};

export default GenericWorkflow;
