import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import RuleBuilder from "./view/RuleBuilder";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
    const [jsonData, setJsonData] = useState(null);
    const [data, setData] = useState(null);
    const [templateTypesOptions, setTemplateTypesOptions] = useState([]);

    //template options
    useEffect(() => {
        fetch("jsonData/options.json")
            .then(data => data.json())
            .then(data => setTemplateTypesOptions([...data.options]));
    }, []);

    //fetching paths for required json
    useEffect(() => {
        fetch("jsonData/path.json")
            .then(data => data.json())
            .then(data => setData(data));
    }, []);

    //fetching actual templates and configs json
    useEffect(() => {
        let obj = {};
        function fetchJSONFiles(key, path) {
            fetch(`jsonData/${path}`)
                .then(data => data.json())
                .then(data => {
                    obj = { ...obj, [key]: data };
                    setJsonData(obj);
                });
        }
        data && Object.keys(data).map(name => fetchJSONFiles(name, data[name]));
    }, [data]);

    return (
        <Switch>
            <Route
                exact
                path="/"
                render={() => (jsonData ? <RuleBuilder templateTypesOptions={templateTypesOptions} jsonData={jsonData} /> : <h1>Loading...</h1>)}
            />
        </Switch>
    );
}

export default App;
